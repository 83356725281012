@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

/* Sticky footer styles
-------------------------------------------------- */

body {
  background: url(/static/media/cogee-bg-cleaning.6266caf6.jpg);
}

.navbar {
  margin-bottom: 0;
}

.jumbotron {
  padding-top: 0rem;
  padding-bottom: 6rem;
  margin-bottom: 0;
  background: none;
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-weight: 700;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 0rem;
}

footer p {
  margin-bottom: .25rem;
  color: white;
}

h1 {
  font-weight: 700;
  letter-spacing: -10px;
  font-size: 200px;
  color: #369b99;
}

h2 {
  font-weight: 400;
  font-size: 26px;
  color: #fff;
}
